//import { unstable_batchedUpdates } from 'react-dom';
import { wsCfg } from '../../settings';
import {
  redrawFut,
  setNotice,
  drawSWB,
  drawUOBox,
} from '../../customApp/containers/helper';
import { store } from '../../redux/store';
import {
  setFetchData,
  // setChartTicker,
  // setFlowChartData,
  setSectors,
} from '../../redux/flow/actions';
import { setMis } from '../../redux/leaders/actions';
import { setGap } from '../../redux/worthWatching/actions';
import { setAiSignals, addAiSignal } from '../../redux/technicals/actions';
import {
  setSignalData,
  setChartData,
  setChartLineData,
} from '../../redux/signals/actions';

window.isUpdateNotificationShown = false;
function getEmail() {
  let pl = localStorage.getItem('pl');
  if (pl) {
    return JSON.parse(pl).user_email;
  } else {
    return 'noemail@tradeui.com';
  }
}
export function iWS(token, updateSocialData) {
  const connection = new WebSocket(wsCfg(token));
  connection.onopen = () => {
    window.con = true;
    window.ab = connection;
    let payload = { type: 'init2', payload: 1, em: getEmail() };

    connection.send(JSON.stringify(payload));
    let payload2 = { type: 'init', payload: 1, em: getEmail() };
    connection.send(JSON.stringify(payload2));
  };

  connection.onclose = either => {
    window.con = false;
    setTimeout(() => {
      iWS();
    }, 5000);
  };

  const SenDirectMessage = () => {
    connection.send(JSON.stringify({ type: 'fetch', payload: 1 }));
  };

  connection.onerror = error => {
    window.con = false;
    console.error('failed to connect', error);
  };
  var lastSScnt = 0;

  connection.onmessage = event => {
    if (event.data) {
      try {
        const location = store.getState().App.current[0];
        var repo = JSON.parse(event.data);
        window.repo = repo;
        if (repo.social) {
          //upSoc(repo.social);
          upSoc(repo.social, updateSocialData);
        } else if (repo.type === 'marketevent') {
          document.getElementById('marketjustopened').click();
        } else if (repo.type === 'pass') {
          localStorage.removeItem('id_token');
        } else if (
          repo.type === 'handshake' &&
          !window.isUpdateNotificationShown
        ) {
          if (localStorage.getItem('tui_v') != repo.payload) {
            localStorage.setItem('tui_u', repo.payload);
            window.UpdateNotifs(repo.payload);
            window.isUpdateNotificationShown = true;
          }
          if (repo.n) {
            if (repo.n === 1) {
              setNotice(true, repo.notice);
            } else {
              setNotice(false, '');
            }
          }
          if (repo.status) {
            if (repo.status == 'c') {
              if (document.getElementById('timeto'))
                document.getElementById('timeto').style.display = 'block';
              document.getElementById('timetoO').style.display = 'none';
            }
            if (repo.status == 'o') {
              if (document.getElementById('timetoO'))
                document.getElementById('timetoO').style.display = 'block';
              document.getElementById('timeto').style.display = 'none';
            }
          }
          //localStorage.getItem('tui_v') != repo.payload ? localStorage.setItem('tui_v',repo.payload) && localStorage.setItem('tui_u',"1") : console.log(1)
        }
        if (repo.type === 's_futures') {
          redrawFut(repo.resultat);
        }
        if (repo.type === 'aiSignal') {
          addAiSignal(repo.signal);
        }

        if (repo.futures) {
          window.futures = repo.futures;
        }
        if (repo.type === 'er') {
          sessionStorage.setItem('er', JSON.stringify(repo.resultat));
        } else if (repo.type === 's_flg' && repo.resultat) {
          if (lastSScnt != repo.resultat.length) {
            if (location !== 'scanner') {
              drawSWB(repo);
            }
          }
          lastSScnt = repo.resultat.length;
        } else if (repo.type === 'SWB') {
          sessionStorage.setItem('SWB', JSON.stringify(repo.resultat));
        } else if (repo && !repo.type) {
          drawUOBox(repo);
        }
        if (repo.type === 'fetch') {
          store.dispatch(setFetchData(repo.fetch));
        }
        if (repo.type === 'sectors') {
          store.dispatch(setSectors(repo.sectors));
        }
        if (repo.type === 'dchart') {
          store.dispatch(setChartData(repo));
        }
        //if (location === 'signals') {+

        if (repo['sgn'] || repo['sgnb'] || repo['sgnhl'] || repo['sgnbo']) {
          //console.log('sgnhlx', repo['sgnhl']);
          // if sgnhl is defined dispatch:
          if (repo['sgnhl']) {
            store.dispatch(setSignalData(repo));
          }
          //store.dispatch(setSignalData(repo));
        }
        if (repo.type === 'hchart') {
          store.dispatch(setChartData(repo.hchart));
        }
        if (repo['cursr']) {
          store.dispatch(setChartLineData(repo.cursr));
        }
        // }
        if (location === 'scanner') {
          if ((repo.type = 'sweepsetups')) {
            drawSWB(repo);
          }
        }
        if (repo.type === 'mis') {
          store.dispatch(setMis(repo.mis));
        }
      } catch {}
    }
  };
}
function capitalizeF(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
function updateFngIndex(data) {
  if (document.getElementById('fngindex')) {
    let numberValue = parseFloat(data[6]);
    let capitalizedWord = capitalizeF(data[5]);
    var finalval = numberValue.toFixed(2);
    let color;
    if (capitalizedWord === 'Fear') {
      color = '#ff2f64';
    } else if (capitalizedWord === 'Greed') {
      color = '#00b399';
    } else {
      color = '#00b399';
    }

    document.getElementById(
      'fngindex'
    ).innerHTML = `<span class='insidebadge' style='color: ${color}'>${capitalizedWord}</span> Market`;
  } else {
    setTimeout(() => updateFngIndex(data), 100);
  }
}

export function upSoc(p, updateSocialData) {
  if (document.getElementById('fngindex')) {
    try {
      updateFngIndex(p);
    } catch (error) {
      console.log(error);
    }
  }

  //   let upArr = `<svg fill='currentColor' xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"  width="12px" height="12px" viewBox="0 0 52 52" enable-background="new 0 0 15 15" xml:space="preserve">
  // <path d="M43.7,38H8.3c-1,0-1.7-1.3-0.9-2.2l17.3-21.2c0.6-0.8,1.9-0.8,2.5,0l17.5,21.2C45.4,36.7,44.8,38,43.7,38z"/>
  // </svg>`;

  //   let downArr = `
  //   <svg fill='currentColor' xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="12px" height="12px" viewBox="0 0 52 52" enable-background="new 0 0 52 52" xml:space="preserve">
  // <path d="M8.3,14h35.4c1,0,1.7,1.3,0.9,2.2L27.3,37.4c-0.6,0.8-1.9,0.8-2.5,0L7.3,16.2C6.6,15.3,7.2,14,8.3,14z"/>
  // </svg>
  // `;
  // if (
  //   document.getElementById('sizzDiv') &&
  //   document.getElementById('stDiv') &&
  //   document.getElementById('wsbDiv')
  // )
  if (p && p.length > 0) {
    let st = JSON.parse(Object.entries(p)[0][1]).symbols;
    let wsb = Object.entries(JSON.parse(p[1]));

    let sizz = p[2];
    let pmar = {};
    let gapp = {};

    if (typeof p == 'object') {
      pmar = JSON.parse(p[2]);
      gapp = Object.entries(JSON.parse(p[3]));
    }

    updateSocialData({
      st,
      wsb,
      sizz,
      pmar,
      gapp,
    });
    // let stDiv = document.getElementById('stDivC');
    // let wsbDiv = document.getElementById('wsbDivC');
    // let sizzDiv = document.getElementById('sizzDivC');
    // let pmarDiv = document.getElementById('pmarDivC');

    // let symbolUrl = 'https://tradeui.com/symbol/';
    // if (wsb.length > 0) {
    //   wsbDiv.innerHTML = '';
    //   wsb.forEach(ee => {
    //     let tickerC = `<span title='${ee[0]} has ${ee[1]} mentions' class='spS stickr'><a target='_blank' class='secondaryColor' href='${symbolUrl}${ee[0]}'>${ee[0]}</a></span><span class='mentions'>${ee[1]}</span>`;

    //     wsbDiv.innerHTML += tickerC;
    //   });
    // }

    // if (st.length > 0) {
    //   stDiv.innerHTML = '';
    //   let i = 0;
    //   st.forEach(ee => {
    //     if (i < 20) {
    //       let tickerC = `<span class='spS stickr'><a class='secondaryColor' target='_blank' href='${symbolUrl}${ee.symbol}'>${ee.symbol}</a></span>`;
    //       stDiv.innerHTML += tickerC;
    //     }
    //     i++;
    //   });
    // }

    // if (gapp.length > 0) {
    //   let gaps = gapp.map(e => ({
    //     ticker: e[0],
    //     gap: e[1],
    //   }));
    //   store.dispatch(setGap(gaps));
    //   sizzDiv.innerHTML = '';
    //   gapp.forEach(ee => {
    //     let tickerC = `<span class='spS stickr'><a class='secondaryColor' target='_blank' href='${symbolUrl}${
    //       ee[0]
    //     }'>${ee[0]} <span style="color:${
    //       ee[1] > 0 ? '#4dfa7b' : '#ff7070'
    //     };margin-left:4px" >${ee[1]} % ${
    //       ee[1] > 0 ? upArr : downArr
    //     }</span> </a></span>`;
    //     sizzDiv.innerHTML += tickerC;
    //   });
    // }

    // if (pmar.length > 0) {
    //   sizzDiv.innerHTML = '';
    //   pmar.forEach(ee => {
    //     let tickerC = `<span class='spS stickr'><a href='/technicals?symbol=${ee.symbol}'>${ee.symbol} +${ee.gap_up_percentage}%</a></span>`;
    //     sizzDiv.innerHTML += tickerC;
    //   });
    // }
  } else {
    // setTimeout(() => {
    //   if (window && window.repo && window.repo.social) {
    //     upSoc(window.repo.social);
    //   }
    // }, 100);
  }
}
