import styled from 'styled-components';
import { palette } from 'styled-theme';
import { transition, borderRadius } from '../../settings/style-util';
import WithDirection from '../../settings/withDirection';

const SidebarWrapper = styled.div`
  .aversion {
    z-index: 1001;
    position: absolute;
    bottom: 0px;
    font-size: 12px;
    color: #96a2c1;
    right: 40px;
  }
  .updateavail {
    position: absolute;
    bottom: 25px;
    right: 40px;
  }
  .isomorphicSidebar {
    z-index: 1000;
    background: #1e1e2d;
    width: 240px;
    flex: 0 0 240px;
    height: 100%;

    .scrollarea {
      height: calc(100vh - 70px);
    }

    @media only screen and (max-width: 767px) {
      width: 200px !important;
      flex: 0 0 200px !important;
    }

    &.ant-layout-sider-collapsed {
      /* max-width: 68px!important; */
      min-width: 60px !important;
      width: 60px !important;
      @media only screen and (max-width: 767px) {
        width: 0;
        min-width: 0 !important;
        max-width: 0 !important;
        flex: 0 0 0 !important;
      }
    }

    .isoLogoWrapper {
      //background: #151521;
      height: 65px;
      margin: 0;
      padding: 6px 15px;
      overflow: hidden !important;

      .ion-ios-sidebar-toggle {
        // background: red;
        & > :first-child {
          transition: fill 0.3s ease;
          fill: #494b74;
        }
        & > :last-child {
          transition: fill 0.3s ease;
          fill: #494b74;
        }

        &:hover {
          & > :first-child {
            fill: #7251e6;
          }
          & > :last-child {
            fill: #7251e6;
          }
        }
      }

      ${borderRadius()};

      h3 {
        height: 100%;
        display: flex;
        width: 100%;

        a {
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          align-items: center;
          font-size: 21px;
          font-weight: 300;
          letter-spacing: 3px;
          text-transform: uppercase;
          color: ${palette('grayscale', 6)};
          text-decoration: none;
          margin: 0 auto;
          text-align: center;
        }
      }
    }

    .ant-menu-submenu-title {
      padding-left: 15px !important;
    }
    .ant-layout-sider-children {
      display: flex;
      flex-direction: column;
    }

    .sidebarScroll {
      // overflow: auto !important;

      & > div {
        overflow: auto !important;
        overflow-x: hidden !important;

        /* width */
        ::-webkit-scrollbar {
          width: 6px;
          box-sizing: content-box;
        }

        /* Track */
        ::-webkit-scrollbar-track {
          background: transparent;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
          background: #3b3b64 !important;
          border-right: 2px solid #060e13;
          border-top: 2px solid #060e13;
          border-bottom: 2px solid #060e13;
        }
      }

      .ant-layout-sider-children div {
        overflow: auto !important;
      }
    }

    &.ant-layout-sider-collapsed {
      .isoLogoWrapper {
        padding: 0;

        h3 {
          a {
            font-size: 27px;
            font-weight: 500;
            letter-spacing: 0;
            margin: 0 auto !important;
            width: max-content !important;
          }
        }
      }
    }
    .ant-menu-inline-collapsed {
      width: 68px;
    }
    ${'' /* .ant-menu-inline-collapsed {
      .ant-menu-submenu > .ant-menu-submenu-title {
        padding: 0 28px !important;
      }
    }*/};
    .ant-menu-vertical > .ant-menu-item,
    .ant-menu-vertical-left > .ant-menu-item,
    .ant-menu-vertical-right > .ant-menu-item,
    .ant-menu-inline > .ant-menu-item,
    .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
    .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
    .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
    .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
      height: 30px;
    }
    .ant-menu-sub.ant-menu-inline > .ant-menu-item,
    .ant-menu-sub.ant-menu-inline
      > .ant-menu-submenu
      > .ant-menu-submenu-title {
      height: 29px;
    }
    .isoDashboardMenu {
      padding-top: 15px;
      padding-bottom: 20px;
      background: transparent;

      a {
        text-decoration: none;
        font-weight: 400;
      }

      .ant-menu-item {
        background: blue;
        width: 100%;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        padding: 0 20px;
        margin: 0;
      }

      .isoMenuHolder {
        display: flex;
        align-items: center;

        i {
          display: none;
          font-size: 19px;
          color: inherit;
          margin: ${props =>
            props['data-rtl'] === 'rtl' ? '0 0 0 10px' : '0 10px 0 0'};
          width: 18px;
          ${transition()};
        }

        @media only screen and (max-width: 1220px) {
          i {
            display: block;
          }
        }
      }

      .anticon {
        font-size: 18px;
        margin-right: 30px;
        color: inherit;
        ${transition()};
      }
      .childico {
        margin-right: 8px;
        font-size: 16px;
        svg {
          display: inline-block;
          width: 19.5px;
          height: 19.5px;
          transform: translate(0, 6px);
        }
      }
      .groupLabel {
        font-weight: bold !important;
      }
      .groupLabel:hover {
        pointer-events: none !important;
      }
      .nav-text {
        font-size: 12px;
        color: #d9d6e6 !important;
        font-weight: 100;
        ${transition()};
      }

      .ant-menu-item-selected {
        background-color: rgba(0, 0, 0, 0.4) !important;
        .anticon {
          color: #fff;
        }

        i {
          color: #bb68a9;
        }

        .nav-text {
          color: #fff;
        }

        & > a {
          color: #fff !important;
        }
      }

      .ant-menu-item {
        &:hover {
          .childico {
            svg {
              color: #7251e6;
            }
          }
        }
        &.ant-menu-item-selected {
          .childico {
            svg {
              color: #7252e6;
            }
          }
        }
      }

      > li {
        &:hover {
          // i,
          .nav-text {
            color: #ffffff;
          }
        }
      }
    }

    .ant-menu-dark .ant-menu-inline.ant-menu-sub {
      background: ${palette('secondary', 5)};
    }

    .ctrme {
      text-align: center;
      height: 100%;
    }

    .ant-menu-submenu-inline,
    .ant-menu-submenu-vertical {
      > .ant-menu-submenu-title {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0 17px 0 30px;

        > span {
          display: flex;
          align-items: center;
        }

        .ant-menu-submenu-arrow {
          left: ${props => (props['data-rtl'] === 'rtl' ? '25px' : 'auto')};
          right: ${props => (props['data-rtl'] === 'rtl' ? 'auto' : '25px')};
          top: 16px;
          opacity: 0.45;

          &:before,
          &:after {
            width: 7px;
            ${transition()};
          }

          &:before {
            transform: rotate(-45deg) translateX(3px);
          }

          &:after {
            transform: rotate(45deg) translateX(-3px);
          }
        }

        &:hover {
          .ant-menu-submenu-arrow {
            &:before,
            &:after {
              color: #ffffff;
            }
          }
        }
      }

      .ant-menu-inline,
      .ant-menu-submenu-vertical {
        > li:not(.ant-menu-item-group) {
          padding-left: ${props =>
            props['data-rtl'] === 'rtl' ? '0px !important' : '15px !important'};
          padding-right: ${props =>
            props['data-rtl'] === 'rtl' ? '15px !important' : '0px !important'};
          font-size: 1em;
          font-weight: 400;
          margin: 0;
          color: inherit;
          ${transition()};

          &:hover {
            a {
              color: #ffffff !important;
            }
          }
        }

        .ant-menu-item-group {
          padding-left: 0;

          .ant-menu-item-group-title {
            padding-left: 100px !important;
          }
          .ant-menu-item-group-list {
            .ant-menu-item {
              padding-left: 125px !important;
            }
          }
        }
      }

      .ant-menu-sub {
        box-shadow: none;
        background-color: transparent !important;
      }
    }

    &.ant-layout-sider-collapsed {
      // .nav-text {
      //   display: none;
      // }

      // .ant-menu-submenu-inline > {
      //   .ant-menu-submenu-title:after {
      //     display: none;
      //   }
      // }

      // .ant-menu-submenu-vertical {
      //   > .ant-menu-submenu-title:after {
      //     display: none;
      //   }

      //   .ant-menu-sub {
      //     background-color: transparent !important;

      //     .ant-menu-item {
      //       height: 35px;
      //     }
      //   }
      // }

      .isomorphicSidebar .isoDashboardMenu .isoMenuHolder i {
        display: block !important;
      }
    }
  }
  .ant-menu-submenu-open .ant-menu-item {
    background: transparent !important;
  }
  .ant-menu-submenu-open .ant-menu-item.ant-menu-item-selected {
    background: #1b1b28 !important;
  }
  .ant-layout-sider-collapsed {
    .isomorphicSidebar {
      border: 2px solid red;
    }
    .isomorphicSidebar .isoDashboardMenu .isoMenuHolder i {
      display: block !important;
    }
  }
  .isomorphicSidebar .isoDashboardMenu .isoMenuHolder i {
    display: block !important;
  }

  .ant-menu-submenu-open .ant-menu-submenu-title .isoMenuHolder i {
    display: none !important;
    color: #7251e6;
  }

  // .ant-layout-sider-collapsed {
  //   .isomorphicSidebar .isoDashboardMenu .isoMenuHolder i{
  //     display: block !important;
  //   }
  // }
`;

export default WithDirection(SidebarWrapper);
