const options = [
  //   {
  //   key: 'trade-ideas',
  //   label: 'sidebar.tradeIdeas',
  //   leftIcon: 'trade-ideas',
  // },

  //     // {
  //     //   key: 'portfolio',
  //     //   label: 'sidebar.portfolio',
  //     //   leftIcon: 'portfolio',
  //     // },
  //     // {
  //     //   key: 'signals',
  //     //   label: 'sidebar.technicals',
  //     //   leftIcon: 'tradeanalysis',
  //     //   // leftIcon: 'ion-arrow-graph-up-right',
  //     // },

  {
    key: 'markets',
    label: 'sidebar.markets',
    leftIcon: 'ion-android-more-vertical',
    children: [
      {
        key: 'overview',
        label: 'sidebar.overview',
        leftIcon: 'overview',
      },
      {
        key: 'news',
        label: 'sidebar.news',
        leftIcon: 'news',
      },

      {
        key: 'earnings',
        label: 'sidebar.earnings',
        leftIcon: 'earnings',
      },
    ],
  },
  {
    key: 'options',
    label: 'sidebar.options',
    leftIcon: 'ion-android-more-vertical',
    children: [
      {
        key: 'aggreflow',
        label: 'sidebar.tickerflow',
        leftIcon: 'aggreflow',
      },
      {
        key: 'flow',
        label: 'sidebar.optionflow',
        leftIcon: 'flow',
        // leftIcon: 'ion-ios-pulse-strong',
      },
      {
        key: 'chain',
        label: 'sidebar.study',
        leftIcon: 'study',
      },

      {
        key: 'insights',
        label: 'sidebar.insights',
        leftIcon: 'insights',
        // leftIcon: 'ion-flame',
      },
      {
        key: 'options',
        label: 'sidebar.optionsmarket',
        leftIcon: 'screener',
      },

      {
        key: 'exposure',
        label: 'sidebar.exposure',
        leftIcon: 'exposure',
      },
      // {
      //   key: 'performance',
      //   label: 'sidebar.flowperformance',
      //   leftIcon: 'performance',
      // },
    ],
  },
  {
    key: 'stock',
    label: 'sidebar.stocks',
    leftIcon: 'ion-android-more-vertical',
    children: [
      {
        key: 'stock',
        label: 'sidebar.stock',
        leftIcon: 'stock',
      },
      {
        key: 'chart-setups',
        label: 'sidebar.flowsetups',
        leftIcon: 'chart-setups',
      },
      {
        key: 'darkpools',
        label: 'sidebar.darkpool',
        leftIcon: 'darkpool',
      },
      {
        key: 'insiders',
        label: 'sidebar.insiders',
        leftIcon: 'insiders',
        // leftIcon: 'ion-clipboard',
      },
      // {
      //   key: 'analysts',
      //   label: 'sidebar.analysts',
      //   leftIcon: 'ion-cash',
      // },
      // {
      //   key: 'prophet',
      //   label: 'sidebar.flowprophet',
      //   leftIcon: 'flowprophet',
      //   // leftIcon: 'ion-flame',
      // },
    ],
  },
  {
    key: 'trading',
    label: 'sidebar.trading',
    leftIcon: 'ion-android-more-vertical',
    children: [
      {
        key: 'alerts',
        label: 'sidebar.alerts',
        leftIcon: 'alerts',
      },
      {
        key: 'watchlists',
        label: 'sidebar.watchlists',
        leftIcon: 'watchlists',
      }, 
      {
        key: 'moneyline',
        label: 'sidebar.moneyline',
        leftIcon: 'moneyline',
      },
      {
        key: 'signals',
        label: 'sidebar.signals',
        leftIcon: 'crypto',
      }, 
      {
        key: 'chart',
        label: 'sidebar.chart',
        leftIcon: 'analysis',
      },
      //hadi les alerts itsifto hena realtime.

      // {
      //   key: 'riskreward',
      //   label: 'sidebar.riskrewardinfo',
      //   leftIcon: 'riskreward',
      //   // leftIcon: 'ion-flame',
      // },
      // {
      //   key: 'chat',
      //   label: 'sidebar.chat',
      //   leftIcon: 'chaticon',
      // },
    ],
  },

  {
    key: 'backtesting',
    label: 'sidebar.backtesting',
    leftIcon: 'ion-android-more-vertical',
    children: [
      // {
      //   key: 'backtester',
      //   label: 'sidebar.backtester',
      //   leftIcon: 'Backtester',
      // }, 
      {
        key: 'strategies',
        label: 'sidebar.backtestingStrategies',
        leftIcon: 'backtester',
      },
      
      // {
      //   key: 'zdte',
      //   label: 'sidebar.zdte',
      //   leftIcon: 'zdte',
      // },
   
      {
        key: 'guide',
        label: 'sidebar.StrategiesHelp',
        leftIcon: 'guide',
      },

      // {
      //   key: 'backtester',
      //   label: 'sidebar.backtestingStocks',
      //   leftIcon: 'backtester',
      // },
      // {
      //   key: 'backtester',
      //   label: 'sidebar.backtestingOptions',
      //   leftIcon: 'backtester',
      // },
      // {
      //   key: 'aifeed',
      //   label: 'sidebar.aifeed',
      //   leftIcon: 'aifeed',
      // },
    ],
  },
  // {
  //   key: 'journal',
  //   label: 'sidebar.journal',
  //   leftIcon: 'ion-android-more-vertical',
  //   children: [
  //     {
  //       key: 'performance',
  //       label: 'sidebar.performance',
  //       leftIcon: 'jperformance',
  //     },
  //     {
  //       key: 'reports',
  //       label: 'sidebar.reports',
  //       leftIcon: 'jreports',
  //     },
  //     {
  //       key: 'calendar',
  //       label: 'sidebar.calendar',
  //       leftIcon: 'calendar',
  //     },
  //     {
  //       key: 'replay',
  //       label: 'sidebar.replay',
  //       leftIcon: 'jreplay',
  //     },
  //   ],
  // },
  // {
  //   key: 'ttools',
  //   label: 'sidebar.ttools',
  //   leftIcon: 'ion-android-more-vertical',
  //   children: [
  //     {
  //       key: 'riskreward',
  //       label: 'sidebar.riskrewardinfo',
  //       leftIcon: 'riskreward',
  //       // leftIcon: 'ion-flame',
  //     },
  //     // {
  //     //   key: 'option-calculator',
  //     //   label: 'sidebar.optioncalculator',
  //     //   leftIcon: 'riskreward',
  //     // },
  //   ],
  // },

  // {
  //   key: 'crypto',
  //   label: 'sidebar.crypto',
  //   leftIcon: 'ion-android-more-vertical',
  //   children: [
  //     {
  //       key: 'crypto',
  //       label: 'sidebar.signals',
  //       leftIcon: 'signals',
  //     },
  //   ],
  // },
  // {
  //   key: 'fin-analyst',
  //   label: 'sidebar.fin-analyst',
  //   leftIcon: 'fin-analyst',
  // },
  // {
  //   key: 'options',
  //   label: 'sidebar.options',
  //   leftIcon: 'ion-ios-pulse-strong',
  // },
  // {
  //   key: 'leaders',
  //   label: 'sidebar.leaders',
  //   leftIcon: 'ion-flame',
  // },
  // {
  //   key: 'study',
  //   label: 'sidebar.study',
  //   leftIcon: 'ion-ios-flask',
  // },

  // {
  //   key: 'insiders',
  //   label: 'sidebar.insiders',
  //   leftIcon: 'ion-clipboard',
  // },

  // {
  //   key: 'glossary',
  //   label: 'sidebar.glossary',
  //   leftIcon: 'ion-map',
  // },

  // {
  //   key: 'chat',
  //   label: 'sidebar.chat',
  //   leftIcon: 'ion-flash',
  // },

  // {
  //   key: 'analyst',
  //   label: 'sidebar.analyst',
  //   leftIcon: 'ion-flame',
  // },
  //hide for now
  // leftIcon: 'ion-flash',

  // {
  //   key: 'agent',
  //   label: 'sidebar.aiagent',
  //   leftIcon: 'ion-code-working',
  // },
  // {
  //   key: 'blank_page',
  //   label: 'sidebar.blankPage',
  //   leftIcon: 'ion-document'
  // },
];
export default options;
